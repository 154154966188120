.App {
  
}

.wavesurfer-region .wavesurfer-handle-start {
  width: 15px !important;
  max-width: none !important;
  background-color: transparent !important;
  background-image: url("/images/icons/icon_region_start.png");
  background-repeat: no-repeat;
  background-size: 15px 128px;
}

.wavesurfer-region .wavesurfer-handle-end {
  width: 15px !important;
  max-width: none !important;
  background-color: transparent !important;
  background-image: url("/images/icons/icon_region_end.png");
  background-repeat: no-repeat;
  background-size: 15px 128px;
}

.main-page {
  margin-top: 75px;
}