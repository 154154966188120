.Window {
    width: 100px;
    margin: 20px 19px;
    cursor: pointer;
    font-size: 0;
}

.Window img {
    width: 100%;
}

.Window img:last-child {
    display: none;
}

@media screen and (max-width: 480px) {
    .Window {
        margin: 20px 10px;
    }
}

@media screen and (max-width: 320px) {
    .Window {
        margin: 20px 5px;
    }
}