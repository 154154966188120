.Loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #212057;
}

.Loader svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.LoaderMini svg {
    height: 100px;
}

.LoaderMini svg circle{
 r: 10;
}