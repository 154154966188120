.Welcome {
    height: 100%;
    width: 100%;
    background-color: #212057;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity 1s ease-out;
    z-index: 999;
}

.Moon {
    position: relative;
    left: 90px;
    top: 23px;
}

.WelcomeContent {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 72px;
    cursor: pointer;
}

.WindowHead {
    font-size: 24px;
    font-weight: 100;
    margin: 0 auto;
    width: 260px;
    margin-bottom: 20px;
    text-align: center;
}
.WindowHead img {
    margin-bottom: 15px;
}

.WindowHead h2 {
    display: inline-block;
    color: white;
    font-family: montserrat;
    font-size: 24px;
    font-weight: 100;
}

.Window {
    background-color: #8990b9;
    width: 550px;
    height: calc( 100vh - 91px);
    margin: 0 auto;
}

.YellowWindow {
    text-align: center;
    position: relative;
    padding: 100px 0 0;
}

.YellowWindow img {
    width: 300px;
}

.YellowWindow div {
    position: absolute;
    top: 50%;
    left: 28%;
    font-family: montserrat;
    font-weight: bold;
    font-size: 23px;
    color: #212157;
}

.YellowWindow h2 {
    font-size: 24px;
    font-weight: 800;
    color: #f1f1f9;
    letter-spacing: 15px;
    position: absolute;
    top: 340px;
    left: 50%;
    text-transform: uppercase;
    transform: translateX(-46%);
}

.YellowWindow p {
    display: inline-block;
    margin-right: 5px;
}

.YellowWindow span {
    color: #ff0000;
}


@media only screen and (max-width: 1200px) {
    .Window {
        width: 500px;
    }

    .YellowWindow {
        padding-top: 80px;
    }
}

@media only screen and (max-width: 1024px) {
    .Window {
        width: 450px;
    }

    .YellowWindow {
        padding-top: 65px;
    }

    .YellowWindow img {
        width: 250px;
    }

    .YellowWindow h2 {
        font-size: 20px;
        letter-spacing: 12px;
        top: 280px;
    }
}

@media only screen and (max-width: 920px) {
    .WelcomeContent {
        transform: translateX(-45%);
        top: 80px;
    }

    .YellowWindow h2 {
        font-size: 20px;
        top: 280px;
    }
}

@media only screen and (max-width: 768px) {
    .Window {
        width: 350px;
    }

    .YellowWindow {
        padding-top: 50px;
    }

    .YellowWindow img {
        width: 200px;
    }

    .WindowHead h2 {
        font-size: 18px;
    }

    .YellowWindow h2 {
        font-size: 15px;
        top: 220px;
        letter-spacing: 10px;
    }

    


}

@media only screen and (max-width: 480px) {
    .WelcomeContent {
        transform: translateX(-50%);
        top: 150px;
    }
}