.NavigationItem {
    display: inline-block;
    margin: 30px 30px 0;
    text-transform: uppercase;
}

.NavigationItem a {
    text-decoration: none;
    color: #fefefe;
}

.NavigationItem a:hover {
    color: #ff0000;
}

.NavigationItem a.Active {
    color: #ff0000;
}

@media screen and (max-width: 768px) {
    .NavigationItem {
        margin: 30px 12px 0;
    }
}


@media screen and (max-width: 480px) {
    .NavigationItem {
        margin: 22px 12px 22px;
        display: block;
        text-align: center;
        font-size: 16px;
    }
}
