.Stair {
    text-align: center;
    margin-top: 30px;
}

.Stair div {
    cursor: pointer;
}

.Stair img {
    position: relative;
}

.Stair p {
    color: red;
    font-weight: bold;
    margin-top: 15px;
}

@media only screen and (max-width: 768px) {
    .Stair img {
        width: 45px;
    }
}

@media only screen and (max-width: 480px) {
    .Stair img {
        width: 40px;
    }
}