.About {
    font-size: 14px;
    font-weight: 400;
    color: white;
    margin-top: 115px;
}

.About h2 {
    font-size: 36px;
    font-weight: bold;
}

.About p {
    line-height: 24px;
}

.AboutTop h2 {
    width: 425px;
    display: block;
}

.AboutTopLeft {
    width: 375px;
    display: inline-block;
    margin-top: 40px;
    vertical-align: top;
}

.AboutTopRight {
    display: inline-block;
    width: 400px;
    margin: 40px 0 0 30px;
}

.AboutTopRight p:nth-of-type(2) {
    margin-top: 40px;
}

.AboutMiddle {
    margin-top: 65px;
}

.AboutMiddleLeft {
    position: relative;
    width: 55%;
    display: inline-block;
    vertical-align: top;
    height: 775px;
}

.AboutMiddleLeft p {
    width: calc( 100% - 130px);
}

.AboutMiddleLeftInside {
    left: -65px;
    width: calc( 100% + 65px);
    position: absolute;
    background-color: #8990b9;
    color: #212057;
    height: 770px;
}

.AboutMiddleLeftInside h2 {
    margin: 50px 0 0 70px;
}

.AboutMiddleLeftInside p {
    margin: 60px 0 0 70px;
}

.AboutMiddleLeft p {
    width: calc( 100% - 130px);
}


.AboutMiddleRight {
    width: 45%;
    display: inline-block;
    margin-top: 100px;
    height: 670px;
}

.AboutMiddleRightInside {
    position: absolute;
    background-color: red;
    height: 670px;
    padding-right: 65px;
}

.AboutMiddleRightInside p {
    margin: 35px 0 80px 50px;
}

.AboutMiddleRightInside h2 {
    margin-left: 50px;
}

.AboutBottom {
    margin-top: 50px;
}

.AboutBottomTitle {
    margin-left: 25px;
}

.AboutBottom div:not(:first-child) {
    width: calc(( 100% - 150px ) / 3 );
    margin-right: 50px;
}

.AboutBottomLeft {
    margin-top: 60px;
    display: inline-block;
}

.AboutBottomCenter {
    display: inline-block;
    vertical-align: top;
    margin-top: 120px;
}

.AboutBottomCenter p:nth-of-type(2) {
    margin-top: 40px;
}

.AboutBottomRight {
    display: inline-block;
    vertical-align: text-top;
}

@media screen and (max-width: 1200px) {
    .About {
        margin-top: 85px;

    }

    .About h2 {
        font-size: 30px;
    }
    
    .AboutTopRight p:nth-of-type(2) {
        margin-top: 30px;
    }

    .AboutMiddle {
        margin-top: 20px;
    }

    .AboutMiddleLeft {
        height: 560px;
    }

    .AboutMiddleLeftInside {
        height: 560px;
    }
    
    .AboutMiddleRight {
        height: 460px;
    }

    .AboutMiddleRightInside {
        height: 460px;
    }

    .AboutMiddleLeftInside H2 {
        margin: 30px 0 0 70px;
    }

    .AboutMiddleLeftInside p {
        margin: 30px 0 0 70px;
    }

    .AboutMiddleRightInside p {
        margin: 25px 0 15px 50px;
    }

    .AboutBottom {
        margin-top: 20px;
    }

    .AboutBottomTitle {
        margin: 20px 25px 0 0;
    }

    .AboutBottomLeft {
        margin-top: 25px;
    }

    .AboutBottomCenter {
        margin-top: 80px;
    }

    .AboutBottomCenter p:nth-of-type(2) {
      margin-top: 20px;
    }

    .AboutBottomRight {
        vertical-align: bottom;
    }
}

@media screen and (max-width: 1024px) {
    .About h2 {
        font-size: 28px;
    }

    .AboutTop h2 {
        width: 605px;
    }

    .AboutTopRight{
        display: inline-block;
        width: 360px;
        margin: 40px 0 0 20px;
    }

    .AboutMiddleRightInside p {
        margin: 15px 0 15px 20px;
    }

    .AboutBottomRight {
        vertical-align: baseline;
    }

    .About {
        margin: 85px 0 0 10px;
    }

    .AboutMiddleRightInside {
        padding-right: 30px;
    }

    .AboutMiddleRightInside h2 {
        margin-left: 20px;
    }

}

@media screen and (max-width: 920px) {
    .About {
        margin-top: 60px;
    }

    .About h2 {
        font-size: 25px;
    }

    .AboutTopLeft {
        width: 360px;
    }

    .AboutTopRight {
        width: 345px;
    }

    .AboutBottomCenter {
        margin: 25px 0 0;
    }

    .AboutBottomRight {
        margin: 25px 0 0;
        vertical-align: top;
    }
}

@media screen and (max-width: 768px) {
    .About h2 {
        font-size: 22px;
    }

    .About p {
        font-size: 12px;
    }

    .AboutTop h2 {
        width: auto;
    }
    .AboutTopLeft {
        display: block;
        width: auto;
    }

    .AboutTopRight {
        width: auto;
        display: block;
        margin: 5px 0 0;
    }

    .AboutTopRight p:nth-child(2) {
        margin: 5px 0 0;
    }
    
    .AboutMiddleLeft {
        display: block;
        width: calc(100% - 10px);
        height: 330px;
    }

    .AboutMiddleLeftInside {
        height: 330px;
        left: -15px;
        width: calc(100% + 40px);
    }

    .AboutMiddleLeftInside h2 {
        margin: 5px 0 0 15px;
    }

    .AboutMiddleLeftInside p {
        width: auto;
        margin: 5px 0 0 15px;
    
    }

    .AboutMiddleRight {
        width: auto;
        margin: 0;
    }

    .AboutMiddleRightInside {
        width: auto;
        padding: 0;
        height: 270px;
        left: 0px;
    }

    .AboutMiddleRightInside p {
        margin: 0 0 0 15px;
    }

    .AboutMiddleRightInside h2 {
        margin: 15px 0 15px 15px;
    }

    .AboutMiddleRight {
        height: 270px;
    }

    .AboutBottom div:not(:first-child) {
        width: auto;
        margin: 5px 0 0;
    }

    .AboutBottomCenter p:nth-of-type(2) {
        margin-top: 5px;
    }
}

@media screen and (max-width: 480px) {
    .About {
        margin: 55px 10px 0 10px;
    }

    .About h2 {
        font-size: 18px;
    }

    .About p {
        font-size: 10px;
    }

    .AboutTop h2 {
        width: auto;
    }

    .AboutTopLeft {
        width: auto;
    }

    .AboutTopRight {
        width: auto;
        margin: 10px 0 0;
    }

    .AboutTopRight p:nth-of-type(2) {
        margin-top: 5px
    }

}