.HomeControls {
    text-align: center;
    margin-bottom: 10px;
    width: calc(100% - 40px);
    position: relative;
}

.HomeControls div {
    display: inline-block;
    color: white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    margin: 0 17px;
}

.HomeControls div:last-child {
    margin-left: 35px;
}

.HomeControls img {
    width: 25px;
    vertical-align: middle;
    margin-right: 10px;
}

@media screen and (max-width: 920px) {
    .HomeControls {
        font-size: 14px;
    }
    .HomeControls img {
        width: 20px;
    }
}

@media screen and (max-width: 480px) {
    .HomeControls {
        font-size: 12px;
    }
    .HomeControls img {
        width: 15px;
    }
}

@media screen and (max-width: 320px) {
    .HomeControls div {
        margin: 0 5px;

    }

    .HomeControls div:first-child {
        margin-left: 35px;
    }

    .HomeControls div:last-child {
        margin-left: 10px;
    }
}