.ComposeButton {
    position: relative;
}

.ComposeButton img {
    position: absolute;
    top: -3px;
    left: 0;
}

.Hide {
    opacity: 0;
}

.ComposeButton span {
    margin-left: 30px;
}

.Red {
    color: red;
}

.Blink {
    animation: blink-animation 1s infinite;
  }
@keyframes blink-animation {
    0%   {opacity: 1;}
    25%  {opacity: 1;}
    50%  {opacity: 1;}
    75%  {opacity: 0;}
    100% {opacity: 0;}
}
