.SideButtons {
    width: 40px;
    display: inline-block;
    vertical-align: bottom;
    text-align: center;
    margin-bottom: 20px;
}

.SideButtons img {
    cursor: pointer;
}

.SideButtons img:nth-child(2) {
    display: inline-block;
    width: 33px;
    margin-top: 15px;
}

.SideButtons img:first-child {
    position: fixed;
    top: 150px;
}

@media screen and (max-width: 920px) {
    .SideButtons img:nth-child(2) {
        width: 25px;
    }
} 

@media screen and (max-width: 480px) {
    .SideButtons {
        width: 35px;
    }

    .SideButtons img {
        width: 17px;
    }

    .SideButtons img:nth-child(2) {
        width: 20px;
    }
}
