.Guide {
    margin-top: 110px;
    font-size: 14px;
    color: white;
    font-weight: 400;
}

.WelcomeToGuideLeft {
    height: 470px;
    width: 275px;
    background-color: #8990b9;
    padding-top: 30px;
    display: inline-block;
}

.WelcomeToGuideLeft h2 {
    font-size: 36px;
    font-weight: bold;
    margin: 0 20px 35px;
    width: 150px;
}

.WelcomeToGuideLeft p {
    display: block;
    width: 240px;
    margin-left: 20px;
}

.WelcomeToGuideRight {
    display: inline-block;
    width: calc(100% - 435px);
    margin: 40px 0 0 80px;
    vertical-align: top;
}

.UsingSteps {
    display: flex;
    flex-wrap: wrap;
}

.UsingSteps div {
    margin: 0 40px 35px 0;
}

.UsingSteps span {
    display: inline-block;
    font-size: 35px;
    font-weight: bold;
}

.UsingSteps p {
    display: inline-block;
    margin-left: 20px;
    width: 150px;
}



.UsingStepsBottom p:nth-child(2) {
    margin: 10px 0 10px;
}

.UsingStepsBottom h2 {
    font-size: 33px;
    font-weight: bold;
    cursor: pointer;
}

.RedBorder {
    position: relative;
}

.RedBorder div {
    position: absolute;
    left: -65px;
    top: 0;
    width: calc( 100% + 130px);
    height: 32px;
    background-color: red;
}

.GuideBottom {
    margin-top: 80px;
    position: relative;
}

.Title {
    font-size: 36px;
    font-weight: bold;
    width: 380px;
}

.InfoLeft {
    display: inline-block;
    width: 40%;
    line-height: 24px;
    margin-right: 75px;
}

.InfoLeft p:first-of-type {
    margin: 40px 0;
}

.InfoLeft p:nth-child(2) {
    margin-bottom: 35px;
}

.InfoLeft span {
    color: #ff0000;
}

.InfoRight {
    display: inline-block;
    width: 40%;
    vertical-align: top;
    margin-left: 75px;
    line-height: 24px;

}

.InfoRight div {
    margin-top: 40px;
}


.InfoRight div span {
    font-weight: bold;
}

.InfoRightBottom span {
    color: red;
}

.Stair {
    position: absolute;
    right: 0;
    bottom: 0;
}

@media screen and (max-width: 1200px) {
    .Guide {
        margin-top: 85px;
    }

    .WelcomeToGuideLeft {
        height: 525px;
    }
    
    .WelcomeToGuideRight {
        margin: 0 0 0 80px;
        height: 450px;
    }

    .UsingSteps div {
        margin-bottom: 20px;
    }

    .InfoLeft {
        margin-right: 40px;
    }

    .InfoRight {
        margin-left: 40px;
    }
}

@media screen and (max-width: 1024px) {
    .Guide {
        margin-top: 50px;
    }


    .WelcomeToGuideLeft h2 {
        font-size: 30px;
    }

    .Title {
        font-size: 30px;
    }

}

@media screen and (max-width: 920px) {
    .Guide {
        margin-top: 50px;
    }

    .WelcomeToGuideLeft {
        height: 420px;
    }

    .WelcomeToGuideRight {
        margin: 0 0 0 60px;
        width: calc(100% - 335px);
    }

    .UsingStepsBottom h2 {
        font-size: 26px;
    }

    .RedBorder div {
        left: -5px;
        width: calc( 100% + 10px );
    }

    .Title {
        width: auto;
    }

    .InfoLeft {
        margin-right: 35px;
    }

    .InfoRight {
        margin-left: 35px;
    }

    .Stair {
        right: 0;
    }
}

@media screen and (max-width: 768px) {
    .Guide {
        margin-top: 50px;
    }

    .WelcomeToGuideLeft {
        height: 425px;
    }

    .WelcomeToGuideRight {
        margin: 0 0 0 20px;
    }

    .UsingSteps div:first-child {
        display: block;
        width: 100%;
    }

    
    .UsingSteps div {
        margin: 0 0 20px;
    }
    
    .UsingSteps span {
        font-size: 20px;
    }

    .UsingSteps p {
        font-size: 12px;
        width: auto;
    }

    .UsingStepsBottom {
        margin-top: 10px;
    }

    .UsingStepsBottom p:nth-child(2) {
        margin: 20px 0 15px;
    }

    .UsingStepsBottom h2 {
        font-size: 25px;
    }

    .Title {
        width: auto;
    }

    .InfoLeft {
        margin: 0 15px;
    }

    .InfoRight {
        margin-left: 15px;
    }

    
}

@media screen and (max-width: 575px) {
    .Guide h2 {
        font-size: 22px;
    }

    .UsingSteps div p {
        display: inline;
    }

    .UsingSteps div {
        margin: 0 0 10px;
    }

    .UsingStepsBottom p:nth-child(2) {
        margin: 10px 0 10px
    }

    .UsingStepsBottom h2 {
        font-size: 19px;
    }

}

@media screen and (max-width: 480px) {
    .Guide {
        text-align: center;
    }
    
    .WelcomeToGuideLeft {
        text-align: left;
        width: 300px;
    }

    .WelcomeToGuideLeft p {
        line-height: 20px;
    }

    .WelcomeToGuideRight {
        width: 300px;
        margin: 20px 0 0 20px;
    }

    .UsingSteps {
        margin-left: 15px;
    }

    .UsingSteps div:first-child {
        width: auto;
    }

    .UsingSteps span {
        font-size: 18px;
    }

    .UsingSteps p {
        font-size: 14px;
    }

    .UsingStepsBottom p {
        line-height: 22px;
    }

    .InfoLeft, .InfoRight {
        display: block;
        width: 300px;
        margin: 0 auto;
    }

    .Stair {
        position: static;
    }
}
