.Logo {
    font-weight: 400;
    font-size: 24px;
    color: white;
    position: absolute;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.Logo a {
    text-decoration: none;
    color: white;
}

.Logo .Caption {
    font-size: 30px;
    font-weight: 700;
    position: absolute;
    top: 30px;
    left: 38px;
    width: 150px;
    line-height: 30px;
    text-transform: uppercase;
    line-height: 1.2;
}

.Logo .Caption p:last-child {
    margin-left: 8px;
}

@media only screen and (max-width: 1024px) {
    .Logo .Caption {
        font-size: 25px;
        top: 26px;
        left: 35px;
    }
}

@media only screen and (max-width: 920px) {
    .Logo .Caption {
        font-size: 25px;
        top: 24px;
        left: 29px;
    }
}

@media only screen and (max-width: 768px) {
    .Logo .Caption {
        font-size: 20px;
        top: 20px;
        left: 26px;
        line-height: 25px;
    }
}

@media only screen and (max-width: 480px) {
}