.Navigation {
    font-weight: 700;
    font-size: 12px;
    color: #e9e9ec;
    text-align: center;
    margin-left: 125px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.Burger {
    position: absolute;
    right: 40px;
    display: none;
    cursor: pointer;
}

.Burger div {
    width: 35px;
    height: 5px;
    background-color: #f00;
    margin: 6px 0;
}

@media screen and (max-width: 1200px) {
    .Navigation {
        font-size: 10px;
    }
}

@media screen and (max-width: 480px) {
    .Navigation {
        position: relative;
    }

    ul {
        background-color: #212057;
        width: 150px;
        position: absolute;
        right: 40px;
        top: 55px;
        display: none;
        border: 2px solid #f00;
        z-index: 9999;
    }

    ul.Show {
        display: block;
    }

    .Burger {
        display: block;
        margin-top: 15px;
    }
}

