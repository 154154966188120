.Button {
    background-color: transparent;
    color: black;
    outline: none;
    cursor: pointer;
    font-family: inherit; 
    padding: 10px;
    margin: 10px;
    font-weight: bold;
}

.Button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Success {
    color: #5C9210;
}

.Danger {
    color: #944317;
}