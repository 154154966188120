.Recording .Title {
    text-align: center;
    margin: 20px 0;
}

.Recording .WaveformWrapper {
 position: relative;
 margin: 0 30px;
}

.Recording .ColorPickerWrapper {
    margin: 40px 20px;
    text-align: center;
}

.Recording .ColorPickerWrapper div {
    display: inline-block;
    margin: 0 10px;
    width: 22px;
    height: 22px;
    border: 1px solid #777;
    vertical-align: middle;
    cursor: pointer;
}

.Recording .ColorPickerWrapper div.Active {
    border: 2px solid black;
}

.Recording .WaveformWrapper img {
    cursor: pointer;
}

.Recording .WaveformWrapper img:last-child{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
}

.Recording .Controls {
    display: flex;
    justify-content: space-between;
}

.Recording .Controls>div {
    text-align: center;
    width: 60%;
}

.Recording .Controls>div:first-child {
    width: 20%;
}

.Recording .Controls>div:last-child {
    width: 20%;
    text-align: right;
}

.Recording .Controls img {
    width: 27px;
    cursor: pointer;
    margin: 10px;
}

.Recording span {
    display: block;
    margin: 20px 10px;
    cursor: pointer;
    text-decoration: underline;
    color: blue;

}

.Controls:last-of-type {
    height: 65px;
    background-color: red;
}

.ManageButtons div {
    display: inline-block;
    /* font-size: 10px; */
}

.ManageButtons a {
    display: inline-block;
    /* font-size: 10px; */
}

.ManageButtons img {
    margin: auto !important;
    margin-top: 10px !important;
}

.ManageButtons span {
    display: block;
    text-decoration: none; 
    margin: auto;
    color: black;
}

.ManageButtons a {
    text-decoration: none;
}

.Red img {
    width: 22px;
    height: 22px;
}

.Orange img {
    width: 22px;
    height: 22px;
}


.Yellow img {
    width: 22px;
    height: 22px;
}


.Green img {
    width: 22px;
    height: 22px;
}

.Blue img {
    width: 22px;
    height: 22px;
}

.Purple img {
    width: 22px;
    height: 22px;
}

.Upload input {
    display: none;
}


