.Team {
    max-width: 1100px;
    background-color: #8990b9;
    margin: 90px auto 0;
}

.Members {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.MembersWindow {
    text-align: center;
    text-transform: uppercase;
    color: white;
    font-size: 12px;
    font-weight: 700;
    margin: 65px auto 0;
}

.MembersWindow span {
    display: block;
    letter-spacing: 5px;
}

.MembersWindow span:first-of-type {
    margin: 20px 0 10px;
}

.SocialIcons {
    margin-top: 25px;
}

.SocialIcons img {
    width: 27px;
    margin: 0 5px;
}

.SocialFooter {
    font-size: 30px;
    font-weight: 900;
    color: white;
    margin-top: 100px;
    text-align: center;
    padding-bottom: 25px;
}

.SocialFooter div {
    display: inline-block;
    vertical-align: baseline;
}

.SocialFooter img {
    display: block;
    margin: 4px 17px 0px;
    width: 27px;
}

.SocialFooter p {
    display: inline-block;
    width: 90px;
    text-transform: uppercase;
    letter-spacing: 20px;
}

@media screen and (max-width: 768px) {
    .Team {
        margin-top: 55px;
    }

    .SocialFooter p {
        width: 200px;
    }

    .Members {
        justify-content: center;
    }

    .MembersWindow {
        margin: 65px 30px 0;
    }
}

@media screen and (max-width: 620px) {
    .MembersWindow {
        margin: 65px 9px 0;
    }
}