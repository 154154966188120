.Building {
    box-sizing: border-box;
    width: calc(100% - 40px);
    margin: 0 auto;
    background-color: #8990b9;
    display: inline-block;
}

.Building.Red {
    border: 1px solid red;
}

.Floors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.SideButtons {
    size: 15px;
}

@media screen and (max-width: 1200px) {
    .Building {
        padding: 0px 55px;
    }
}

@media screen and (max-width: 1024px) {
    .Building {
        padding: 0 30px;
    }
}

@media screen and (max-width: 768px) {
    .Building {
        padding: 0 20px;
    }
}