.Moon {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background-color: #ff0000;
}

@media only screen and (max-width: 1024px) {
    .Moon {
        width: 100px;
        height: 100px;
    }
}

@media only screen and (max-width: 920px) {
    .Moon {
        width: 95px;
        height: 95px;
    }
}

@media only screen and (max-width: 768px) {
    .Moon {
        width: 80px;
        height: 80px;
    }
}