@font-face {
    font-family: montserrat;
    src: url("/fonts/Montserrat-thin.ttf");
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: montserrat;
    src: url("/fonts/Montserrat-Regular.ttf");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: montserrat;
    src: url("/fonts/Montserrat-bold.ttf");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: montserrat;
    src: url("/public/fonts/Montserrat-ExtraBold.ttf");
    font-style: normal;
    font-weight: 800;
}