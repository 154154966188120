@import '~reset-css/reset.css';

@import './fonts.css';

body {
  background-color: #212057;
  padding: 25px 65px;
  font-family: montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header .navigation-wrapper {
  position: relative;
  height: 60px;
}

header {
  position: fixed;
  top: 0;
  background-color: #212057;
  width: calc(100% - 65px);
  z-index: 6666;
  padding-top: 25px;
  height: 112px;
  left: 0;
  padding-left: 65px;
}

.content {
  padding-top: 60px;
}

button {
  font-family: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media only screen and (max-width: 920px) {
  body {
    padding: 25px 5px;
  }
}